export type Wave =
  | 'yellow'
  | 'skin1'
  | 'skin2'
  | 'skin3'
  | 'skin4'
  | 'skin5'
  | 'rainbow'
  | 'blue'
  | 'green'
  | 'orange'
  | 'purple'
  | 'teal'
  | 'purple8'
  | 'heart'
  | 'peace'
  | 'emoji4'
  | 'tiedye';

type FriendRequestStatus =
  | 'requested'
  | 'approved'
  | 'declined'
  | 'cancelled'
  | 'not_requested'
  | 'friends';

type Gender = 'female' | 'male' | 'not_listed' | 'prefer_not_to_say' | 'non_binary';

type CommunicationPreference = 'sms' | 'push_notifications' | 'email';

type NotificationTimePreference = 'anytime' | 'morning' | 'afternoon' | 'evening';

type CommunityCommunicationPreferences =
  | 'community_sms'
  | 'community_push_notifications'
  | 'community_email';

type CommunicationInterests =
  | 'art'
  | 'music'
  | 'home_and_garden'
  | 'pets'
  | 'travel'
  | 'cars'
  | 'sports_and_fitness'
  | 'food'
  | 'movies'
  | 'books'
  | 'fashion'
  | 'beauty';

type Passions =
  | 'health'
  | 'equality'
  | 'water'
  | 'climate'
  | 'peace'
  | 'education'
  | 'poverty'
  | 'animal_welfare';

type ModerationStatus = 'unknown' | 'pending' | 'approved' | 'auto_approved' | 'rejected';

export type DisplayLanguage = 'en' | 'de' | 'es' | 'fr' | 'hi' | 'it';

export type TokenResponse = {
  accessToken: string;
};

export type PostType = {
  id: string;
  caption?: {
    mentions: [];
    content: string;
  };
  media: {
    height: number;
    aspectRatio: string;
    blurHash: string;
    type: string;
    uri: string;
    uriMedium: string;
    uriSmall: string;
    width: number;
    cover: {
      blurHash: string;
      height: number;
      uri: string;
      uriMedium: string;
      uriSmall: string;
      width: number;
    };
  };
  postPublisher: {
    id: string;
    username: string;
    isVerifiedCreator: boolean;
    profilePictureUri: string;
  };
  moderationDecision?: string;
  rejectedReason?: string;
  visibility?: string;
  creationDate: string;
  publishDate: string;
  creationDateTimestampEpochInMilliseconds: number;
  publishDateTimestampEpochInMilliseconds: number;
  eightStage: boolean;
  sharingOptions: string;
  likesCount: number;
  sharesCount: number;
  commentsCount: number;
  thumbnailWidth: number;
  thumbnailHeight: number;
  externalLink: string;
  influencerId: string;
  influencerUsername?: string;
  influencerUserName?: string;
  influencerProfilePicture?: string;
  influencerProfileUrl?: string;
  mediaVideoCoverImageUri: string;
  imageUri?: string;
  isEightStage?: boolean;
  groupName?: string;
  groupId?: string;
  groupCoverImageUri?: string;
  coverImageWidth?: number;
  coverImageHeight?: number;
  coverImageAspectRatio?: string;
  coverImageUri?: string;
  mediaVideoKey?: string;
  mediaVideoDuration?: number;
  mediaVideoAspectRatio?: string;
  mediaVideoCoverImageHeight?: number;
  mediaVideoCoverImageWidth?: number;
  mediaUploadCompleted?: boolean;
  mentionDetails?: MentionRequestItem[];
  tileType?: unknown;
  isInfluencerVerifiedCreator?: boolean;
  mentionedId?: number;
  username?: string;
};

export type PostTypeAsLoggedInUser = {
  latestLikeByFriendName: string;
  latestShareByFriendName: string;
  latestLikeByFriendId: string;
  latestShareByFriendId: string;
  amIFollowing: boolean;
  videoUploadStatus: 'unknown' | 'complete' | 'ingest' | 'error' | 'uploading';
  mentionDetails: {
    mentionedId: string;
    username: string;
  }[];
  mediaUploadCompleted: boolean;
} & PostType &
  PostOverview;

export type PostOverview = {
  likesOverviewDetails: {
    likesCount: number;
    liked: boolean;
  };
  commentsOverviewDetails: { commentsCount: number };
  sharesOverviewDetails: { sharesCount: number; shared: boolean };
  isFollowed: boolean;
};

export type CharityCategory = {
  name: string;
  icon: string;
  value: string;
};

export type CharityType = {
  id: string;
  emailAddress: string;
  name: string;
  about: string;
  url: string;
  category: string;
  country: string;
  currencyCode: string;
  coverImageUri: string;
  logoUri: string;
  isPayItForwardPartner: boolean;
  isCampaignDonationPartner: boolean;
  order: number;
  logoKey: string;
};

export type ErrorResponse = {
  status: number;
  data: ErrorData;
  code: number;
};

export type BackendErrorResponse = {
  code: number;
  domain: string;
  message: string;
};

type ErrorData = {
  code: number;
  domain: string;
  message: string;
};

type GroupsType = {
  groupId: number;
  name: string;
  description: string;
  coverPhotoUri: string;
  id: number;
  userName: string;
  profilePicUri: string;
  isVerifiedCreator: boolean;
  membersCount: number;
  privacyPreference: unknown;
  memberStatus: string;
};

type GroupType = {
  name: string;
  description?: string;
  groupId: string;
  membersCount: number;
  coverPhotoUri: string;
  privacyPreference: string;
  groupCreatorDetails: object;
};

export type LoginResponse = {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  tokenType: string;
  isSignUpCompleted: boolean;
};

export type Mention = {
  id: string;
  profilePictureUri: string;
  name: string;
  username: string;
  isVerifiedCreator: boolean;
  eightWave: Wave;
};

export type MyProfile = {
  isBirthdayEditable: boolean;
  isInSupportedCountry: boolean;
  friendRequestDetails: {
    id: string;
    status: FriendRequestStatus;
    createdAt: string;
    createdAtTimestampInMilliseconds: number;
    isOutFriendRequest: boolean;
  };
  friendDetails: {
    id: string;
    createdAt: string;
    createdAtInMilliseconds: number;
  };
  followingDetails: {
    id: string;
    createdAt: string;
    createdAtInMilliseconds: number;
  };
  hasFriendRequest: boolean;
  isFriends: boolean;
  walletId: string;
  followersCount: number;
  followingCount: number;
  friendsCount: number;

  emailAddress: string;
  phoneNumber: {
    regionCode: string;
    countryCode: string;
    number: string;
  };
  isPhoneNumberVerified: boolean;
  isEmailVerified: boolean;
  birthDate: string;
  gender: Gender;
  communicationPreference: CommunicationPreference[];
  notificationTimePreference: NotificationTimePreference;
  sponsorshipPerDayMaxLimit: number;
  paypalEmail: string;
  isInstagramConnected: boolean;
  isFacebookConnected: boolean;
  isTwitterConnected: boolean;
  isTikTokConnected: boolean;
  interests: {
    interests: [
      {
        code: string;
        name: string;
      }
    ];
  };
  currencyIsoSymbol: string;
  currencySymbol: string;
  countryCode: string;
  ipLocation: {
    continentCode: string;
    continentName: string;
    countryCode: string;
    countryName: string;
    regionCode: string;
    regionName: string;
    city: string;
    zip: string;
    coordinates: {
      longitude: number;
      latitude: number;
    };
  };
  isPMCEligible: boolean;
  communityCommunicationPreferences: CommunityCommunicationPreferences[];
  wave: Wave;
  currencyInfo: {
    region: string;
    currencyIsoSymbol: string;
    currencySymbol: string;
  };
  amIFollowing: boolean;
  communicationInterests: CommunicationInterests;
  profileCompleted: boolean;
  communicationsCompleted: boolean;
  onboardingCompleted: boolean;
  geoLocation: {
    continentCode: string;
    continentName: string;
    countryCode: string;
    countryName: string;
    regionCode: string;
    regionName: string;
    city: string;
    zip: string;
    coordinates: {
      longitude: number;
      latitude: number;
    };
  };
  mentionPrivacyPreference: 'anyone' | 'friends' | 'noOne';
  birthdayPrivacyPreference: 'private' | 'onlyFriends';
  contactSyncingPreference: 'Disabled' | 'Enabled';
  contactSuggestionsPreference: 'Disabled' | 'Enabled';
} & PublicUserProfile;

export type PaginatedResponse<T> = {
  data: T[];
  pages: number;
  total: number;
  page?: number;
};

export type MentionRequestItem = {
  mentionedId: string;
  username: string;
};

export type Hashtag = {
  hashtag: string;
  count: number;
};

export type PreSignedURLResponse = {
  preSignedUrl: string;
  expirationDate: string;
};

export type Anthem = {
  anthemId: string;
  thumbnailUrl: string;
  title: string;
  artist: string;
  openAnthemUrl: string;
  previewUrl?: string;
};

export type PublicUserProfile = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  bio: string;
  profilePictureUri?: string;
  profileBackgroundPictureUri?: string;
  passions?: Passions[];
  eightWave: Wave;
  moderationStatus: ModerationStatus;
  anthem?: Anthem;
  externalLinks?: {
    title: string;
    url: string;
  }[];
  bioProfileLinks?: {
    citizenId: string;
    username: string;
  }[];
  createdDate: string;
  updatedDate: string;
  createdTimestampEpochInMilliseconds: number;
  updatedTimestampEpochInMilliseconds: number;
  attributionLink?: string;
  loveMeterVisibility?: 'Anyone' | 'OnlyMe';
} & ProfileSocialOverview;

export type PublicUserProfileWithSocialStatus = {
  friendRequestDetails?: {
    id: string;
    status: FriendRequestStatus;
    createdAt: string;
    createdAtTimestampInMilliseconds: number;
    isOutFriendRequest: boolean;
  };
  amIFollowing: boolean;
  isFriends: boolean;
} & PublicUserProfile;

export type ProfileSocialOverview = {
  followersCount?: number;
  loveMeterCount?: number;
  followingCount?: number;
  friendsCount?: number;
};

export type SuggestionType = {
  id?: string;
  name?: string;
  username?: string;
  citizenId?: string;
  citizenName?: string;
  citizenUsername?: string;
  profilePictureUri: string;
  isVerifiedCreator: boolean;
  connectingFollowerName: string;
  connectingFollowersCount: number;
  connectingFollowerUsername: string;
  commonFriendUsername: string;
  commonFriendsCount: number;
};

export type MutualConnection = {
  id: string;
  username: string;
  isVerifiedCreator: boolean;
  name: string;
  profilePictureUri: string;
  mutualConnectionType: 'unknown' | 'friend' | 'follow'[];
};

export type TransifexLanguage = {
  code: string;
  name: string;
  localized_name: string;
  rtl: boolean;
};

export type DonationParams = {
  availableBalance: string;
  countryCode: string;
  currencySymbol: string;
  influencerId: string;
  amount?: string;
  // type: string;
};

export type BirthdayDonationParams = {
  username: string;
  fromInfluencerId: string;
} & Omit<DonationParams, 'type'>;

export enum DonationType {
  PLEDGE = 'pledge',
  GIVE = 'give',
}

export enum supportedLanguage {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  IT = 'it',
}
