import React, { ReactNode } from 'react';
import { StyledComponent } from 'styled-components';

import ReactModal from 'react-modal';
import { isBrowser } from 'utils/helper';
import { StyledModalOverlay } from './Modal.styled';

interface modalProps extends ReactModal.Props {
  ModalStyle: StyledComponent<'div', any, {}, never>;
  OverlayStyle?: StyledComponent<'div', any, {}, never>;
  children: ReactNode;
}

const Modal: React.FC<modalProps> = ({ ModalStyle, OverlayStyle, children, ...props }) => {
  return (
    <>
      {isBrowser() && (
        <ReactModal
          className="_"
          appElement={document.getElementById('root') as HTMLElement}
          overlayClassName="_"
          closeTimeoutMS={400}
          contentElement={(props, contentElement) => (
            <ModalStyle {...props}>{contentElement}</ModalStyle>
          )}
          overlayElement={(props, contentElement) =>
            OverlayStyle ? (
              <OverlayStyle {...props}>{contentElement}</OverlayStyle>
            ) : (
              <StyledModalOverlay {...props}>{contentElement}</StyledModalOverlay>
            )
          }
          ariaHideApp={false}
          {...props}
        >
          {children}
        </ReactModal>
      )}
    </>
  );
};

export default Modal;
