const config = {
  environment: process.env.NEXT_PUBLIC_CUSTOM_ENV,
  baseUrl: process.env.NEXT_PUBLIC_API,
  webappDomain: process.env.NEXT_PUBLIC_WEBAPP_DOMAIN,
  envPackageName: process.env.NEXT_PUBLIC_PACKAGE_NAME,
  charitiesAPI: process.env.NEXT_PUBLIC_CHARITIES_API,
  groupsAPI: process.env.NEXT_PUBLIC_GROUPS_API,
  graphsAPI: process.env.NEXT_PUBLIC_GRAPHS_API,
  usersAPI: process.env.NEXT_PUBLIC_USERS_API,
  paymentsApi: process.env.NEXT_PUBLIC_PAYMENT_API,
  identityApi: process.env.NEXT_PUBLIC_IDENTITY_API,
  socialApi: process.env.NEXT_PUBLIC_SOCIAL_API,
  contentApi: process.env.NEXT_PUBLIC_CONTENT_API,
  appsflyerURL: process.env.NEXT_PUBLIC_APPSFLYER_TEMPLATE_URL,
  brazeEndpoint: process.env.NEXT_PUBLIC_BRAZE_ENDPOINT ?? '',
  brazeApiKey: process.env.NEXT_PUBLIC_BRAZE_KEY ?? '',
  appURIScheme: process.env.NEXT_PUBLIC_APP_URI_SCHEME,
  auth: {
    machineId: 'sharing.client',
    clientSecret: process.env.NEXT_PUBLIC_CLIENT_SECRET,
    clientId: 'citizens.client',
    machineSecret: process.env.NEXT_PUBLIC_MACHINE_SECRET,
  },
  googleAuth: {
    clientId: process.env.GOOGLE_CLIENT_ID,
    clientSecret: process.env.GOOGLE_CLIENT_SECRET,
  },
  birthdayCharity: {
    id: process.env.NEXT_PUBLIC_BIRTHDAY_ID,
    countryCode: 'GB',
    url: 'https://www.charitywater.org/uk',
    name: 'charity:water',
  },
  transifexToken: process.env.NEXT_PUBLIC_TRANSIFEX_TOKEN,
};

export default config;
